import {
  ArtistIntroCard,
  AnnouncementModal,
  Benefits,
  ButtonGroup,
  CardOverlay,
  CardFullWidth,
  Calendar,
  ShowsCalendar,
  Carousel,
  FlexBox,
  CarouselFullScreen,
  CombinationHeader,
  StickyDisclaimer,
  Disclaimer,
  EdpFullScreenVideo,
  Footer,
  FooterStandalone,
  Gallery,
  Grid,
  Header,
  NavigationHeader,
  HeaderStandalone,
  Hero,
  InteractiveOverlayCard,
  HeroEDPHeaderV2,
  RulesCard,
  JumpLinkGroup,
  FormModule,
  HeroEdpHeader,
  HeroFullScreen,
  HeroGeneralInfo,
  IframeVideo,
  InfoBar,
  InfoBarSection,
  NotificationBanner,
  HeroFullScreenVideo,
  TileCarousel,
  Locations,
  LostandFound,
  PressLinks,
  ScrollAnimation,
  CardList,
  GroupVotingButton,
  CardRow,
  IconButtonsCard,
  SignUp
} from '~modules'
import {
  Benefit,
  Button,
  Card,
  CardMedia,
  CareerForm,
  CarouselSlide,
  Divider,
  EdpHeader,
  Form,
  Heading,
  ImageLink,
  Leaderboard,
  Location,
  Module,
  Modal,
  MediaLegend,
  Paragraph,
  Pill,
  PressLink,
  Vimeo,
  PlayButton,
  PauseButton,
  GroupTicketingCard,
  DoesNotExist
} from '~elements'

export const components = {
  ArtistIntroCard,
  AnnouncementModal,
  Benefit,
  Benefits,
  Button,
  ButtonGroup,
  Card,
  CardOverlay,
  CardRow,
  Calendar,
  ShowsCalendar,
  CardFullWidth,
  FlexBox,
  CardMedia,
  CareerForm,
  Carousel,
  CarouselFullScreen,
  CarouselSlide,
  CombinationHeader,
  Divider,
  StickyDisclaimer,
  Disclaimer,
  EdpFullScreenVideo,
  EdpHeader,
  Footer,
  FooterStandalone,
  Form,
  FormModule,
  Gallery,
  Grid,
  Header,
  NavigationHeader,
  HeaderStandalone,
  Heading,
  TileCarousel,
  Hero,
  HeroEdpHeader,
  HeroFullScreen,
  HeroFullScreenVideo,
  HeroGeneralInfo,
  IframeVideo,
  InfoBar,
  InfoBarSection,
  InteractiveOverlayCard,
  HeroEDPHeaderV2,
  NotificationBanner,
  ImageLink,
  JumpLinkGroup,
  Leaderboard,
  Location,
  Locations,
  LostandFound,
  MediaLegend,
  Module,
  Modal,
  Paragraph,
  Pill,
  PressLink,
  PressLinks,
  ScrollAnimation,
  Vimeo,
  CardList,
  RulesCard,
  PlayButton,
  PauseButton,
  GroupVotingButton,
  IconButtonsCard,
  GroupTicketingCard,
  SignUp,
  DoesNotExist
}
